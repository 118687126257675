import React, { useState, useEffect } from "react";
import { errorToaster, successToaster } from "../../helpers/utils";
import { subscribeUser, getUserById } from "../../crud/users.crud";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as workerTimers from "worker-timers";
import dynamic from "next/dynamic";
import { toast } from "react-toastify";

const Modal = dynamic(() => import("react-modal"), {
	ssr: false
});

const customNewsletterModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		minWidth: "32px",
		maxWidth: "505px",
		minheight: "32px",
		maxheight: "80vh",
		padding: "20px 10px",
		borderRadius: "5px",
		border: "none",
		width: "85%"
	},
	overlay: {
		overflowX: "scroll"
	}
};

const SubscribeNewsletterModal = props => {
	const [loading, setLoading] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(true);
	const [userEmail, setUserEmail] = useState("");

	const clientType = 1;
	const subscriptionSource =
		props.route === "/"
			? 1
			: props.route === "/resources"
				? 5
				: props.route === "/communities"
					? 4
					: 1;

	useEffect(() => {
		if (localStorage) {
			const isSubscribedBool = localStorage.getItem("isSubscribed");
			if (isSubscribedBool !== "false") {
				if (props.signedIn === true) {
					getProfile();
				} else if (
					props.signedIn === false &&
					(props.route === "/" ||
						props.route === "/resources" ||
						props.route === "/communities")
				) {
					setTimeout(() => {
						setIsSubscribed(false);
					}, 60000);
				}
			}
		}
	}, [props.signedIn]);

	useEffect(() => {
		if (isSubscribed === false && localStorage) {
			localStorage.setItem("isSubscribed", false);
		}
	}, [isSubscribed]);

	const getProfile = async () => {
		try {
			const res = await getUserById();
			if (res.data.email_subscription) setIsSubscribed(true);
			if (
				res.data.email_subscription == false &&
				(props.route === "/" ||
					props.route === "/resources" ||
					props.route === "/communities")
			) {
				setTimeout(() => {
					setIsSubscribed(false);
				}, 60000);
				setUserEmail(res.data.email_id);
				// return () => {
				//     clearInterval(interval);
				// }
			}
		} catch (err) {
			console.log(err);
			setIsSubscribed(false);
		}
	};

	let formik = useFormik({
		enableReinitialize: true,
		initialValues: { email_id: userEmail },
		validationSchema: Yup.object({
			email_id: Yup.string().required("Email is required").email("Please enter a valid email")
		}),
		onSubmit: async values => {
			try {
				let res = await subscribeUser(values.email_id, clientType, subscriptionSource);
				if (res.status === 200) successToaster("Subscribed Successfully");
				if (props.signedIn === true) {
					getProfile();
				}
				setLoading(false);
				setIsSubscribed(true);
			} catch (err) {
				console.log(err);
				errorToaster("Something went wrong, could not subscribe user");
				setLoading(false);
				setIsSubscribed(false);
			}
		}
	});

	return (
		<div className="font-figtree">
			{!isSubscribed && (
				<Modal
					isOpen={!isSubscribed}
					onRequestClose={() => setIsSubscribed(true)}
					shouldCloseOnEsc={false}
					shouldCloseOnOverlayClick={false}
					style={customNewsletterModalStyles}
					contentLabel="Subscribe Modal"
				>
					<div>
						<img src="https://d2nnrpx7gk2my2.cloudfront.net/files/671fc9f15e9a8200087c60f2/431.svg" className="newsletter font-figtree " />
						<img
							src="https://d2nnrpx7gk2my2.cloudfront.net/files/671fcc5d5e9a8200087c60f4/close.svg"
							className="close-icon"
							onClick={() => setIsSubscribed(true)}
						/>
					</div>
					<div className="newsletter-content font-figtree d-flex flex-column gap-3">
						<div className="heading">Subscribe To Our Newsletter</div>
						<div className="sub-heading">
							No Spam - We Promise!
						</div>
						<div className="sub-heading">
							You can unsubscribe at anytime ✌️
						</div>

						<div className="email-div">
							<input
								className="email-input"
								placeholder="Enter your email address"
								value={formik.values.email_id}
								onChange={e => formik.setFieldValue("email_id", e.target.value)}
							/>
							<button
								className="send-btn"
								onClick={() => formik.submitForm()}
								disabled={formik.errors?.email_id}
							>
								Subscribe
							</button>
						</div>
						{formik.errors?.email_id && (
							<span className="error-text">{formik.errors.email_id}</span>
						)}
					</div>
				</Modal>
			)}

			<style jsx>{`
				.newsletter {
					background-color: #fff;
					width: 100%;
					max-width: 200px;
					height: auto;
					display:flex;
					margin:auto;
				}
				.newsletter-content {
					margin: 25px 0 0px 0;
					text-align: center;
					position: relative;
				}
				.close-icon {
					position: absolute;
					top: 15px;
					right: 15px;
					cursor: pointer;
				}
				.heading {
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
					color: #001A47;
				}
				.sub-heading {
					//margin-top: 15px;
					font-weight: 400;
					font-size: 16px;
					line-height: 10.24px;
					color: #424242;
					padding: 0 10px;
				}
				.email-div {
					margin-top: 15px !important;
					margin-bottom: 15px !important;
					display: flex;
					//align-items: center;
					justify-content: space-between;
					position: relative;
					border:0.77px solid #D0D5DD !important;
					border-radius:2px;
					max-width:444px;
					margin:auto;
					width:100%;

				}
				.email-input {
					border: none;
					background: #FFFFFF;
					border-top-left-radius: 2px;
					border-bottom-left-radius: 2px;
					padding: 10px;
					width: 70%;
					outline: none;
					margin-right: 44px;
				}
				.send-btn {
					border: none;
					background: #002E6E;
					border-top-right-radius: 2px;
					border-bottom-right-radius: 2px;
					padding: 7.7px 20px;
					//position: absolute;
					//bottom: 0;
					//right: 48px;
					font-weight:700;
					color:#fff;
					font-size:16px;
				}
				.error-text {
					margin-left: 5px;
					font-size: 14px;
					color: red;
				}

				@media (max-width: 420px) {
					.email-input {
						width: 80%;
						padding-right: 10px;
					}
				}
			`}</style>
		</div>
	);
};

export default React.memo(SubscribeNewsletterModal);
